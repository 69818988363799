import { useInfinitePrograms } from "@/api/program";
import { ProgramType } from "@/types/programs";
import { StepHeader } from "./stepHeader";
import { StepFooter } from "./stepFooter";
import i18n from "@/i18n";

import useChallengeFormStore from "@/store/challengeStore";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radiogroup";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { useLocation } from "@tanstack/react-router";
import { useState } from "react";

export const CauseStep = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [activeTab, setActiveTab] = useState(search.tab ?? "");
  const {
    nextStep,
    setProgram,
    currentProgram,
    setProgramMessage,
    setProgramMealPriceEGP,
    setProgramMealPriceUSD,
    setProgramImage,
    setProgramName,
    setProgramId,
  } = useChallengeFormStore();

  const { data: programs, isLoading } = useInfinitePrograms({
    page_size: "8",
    lang: i18n.language,
    category: "",
    ordering: "rank",
    donationtype: activeTab,
    isSpecial: "False",
    isItemization: "False",
  });

  const handleProgramSelection = (program: ProgramType) => {
    setProgram(program.id);
    setProgramMessage(program.message);
    setProgramMealPriceEGP(program.meal_price_egp);
    setProgramMealPriceUSD(program.meal_price_usd);
    setProgramImage(program.main_image.minio_url);
    setProgramName(program.title);
    setProgramId(program.id);
  };

  return (
    <>
      <StepHeader />
      <div className="scrollbar-hide bg-background mt-2 flex h-screen w-full flex-col gap-16 overflow-y-auto pt-4 md:mb-[90px] md:pt-16 lg:max-w-[615px]">
        <div className="bg-background mb-8 flex h-full w-full flex-col gap-4 md:mt-0 md:gap-8 md:px-6">
          <h3 className="w-full py-2 text-xl font-[650] leading-[120%] sm:text-[32px] md:py-0">
            {t("challenge.selectCause")}
          </h3>
          <p className="">
            {t("challenge.selectCauseDescription1")}{" "}
            <span className="font-bold">
              {t("challenge.selectCauseDescription2")}
            </span>{" "}
            {t("challenge.selectCauseDescription3")}
          </p>
          <div className="flex gap-1 rounded-xl bg-[#E2DDD7] p-1">
            <button
              onClick={() => setActiveTab("")}
              className={`h-12 flex-1 rounded-lg pl-4 pr-3 text-sm md:text-base ${
                activeTab === ""
                  ? "bg-[#232329] font-[650] text-[#E2DDD7]"
                  : "font-[350] text-[#4F4754]"
              }`}
            >
              {t("challenge.all")}
            </button>
            <button
              onClick={() => setActiveTab("Sadaqah Jariya")}
              className={`h-12 flex-1 rounded-lg pl-4 pr-3 text-sm md:text-base ${
                activeTab === "Sadaqah Jariya"
                  ? "bg-[#232329] font-[650] text-[#E2DDD7]"
                  : "font-[350] text-[#4F4754]"
              }`}
            >
              {t("challenge.sadaqah")}
            </button>
          </div>
          <RadioGroup
            value={currentProgram}
            className="flex w-full flex-col gap-6 pt-4 md:pt-0"
          >
            {!isLoading &&
              programs?.pages.map((page) =>
                page.results.map((program) => (
                  <label
                    key={program.id}
                    className={`flex cursor-pointer items-center gap-4 rounded-lg border bg-[#E2DDD7] p-4 transition-colors last:mb-12 md:gap-6 md:p-6 md:last:mb-16  ${
                      currentProgram === program.id
                        ? "border-[#232329]"
                        : "border-[#CCC2BF]"
                    }`}
                  >
                    <RadioGroupItem
                      border={
                        currentProgram === program.id
                          ? "border-none"
                          : "border-2 border-[#232329]"
                      }
                      color="bg-[#232329]"
                      value={program.id}
                      onClick={() => handleProgramSelection(program)}
                    />
                    <div className="h-[54px] w-[54px] flex-shrink-0 overflow-hidden rounded-lg bg-gray-300">
                      <img
                        className="h-full w-full object-cover"
                        src={program.main_image.small_url}
                        alt="challenge picture preview"
                      />
                    </div>
                    <div className="flex flex-col items-start gap-2">
                      <div className="truncate font-[650]">{program.title}</div>
                      <div className="flex flex-wrap items-center gap-4">
                        {program.category.map((category) => (
                          <Button
                            key={category.title}
                            className="rounded bg-[#EDEAE4] px-2 py-1 text-sm"
                          >
                            {category.title}
                          </Button>
                        ))}
                      </div>
                    </div>
                  </label>
                )),
              )}
          </RadioGroup>
        </div>
      </div>
      <StepFooter
        nextStep={nextStep}
        isDisabled={!currentProgram.length}
      ></StepFooter>
    </>
  );
};
