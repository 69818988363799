import React from "react";
import { useContactInfo, useFAQsInfo } from "@/api/information";
import { MailIcon } from "@/assets/icons/MailIcon";
import { PhoneIcon } from "@/assets/icons/PhoneIcon";
import ContactCard from "../contactCard";
import { useTranslation, Trans } from "react-i18next";
import { Header } from "../header";
import { FAQsComponent } from "@/components/Helpers/FaqsComponent";
import { AppShell } from "@/components/Navbar";
import clsx from "clsx";
import { useScrollToShowAppShell } from "../hooks/useScrollToShowAppShell";
import { Helmet } from "react-helmet-async";
import faqData from "@/utils/faq.json";

export const FAQsPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { data: faqs } = useFAQsInfo({ lang: i18n.language });
  const { data: contact } = useContactInfo();
  const { isScrolled, headerRef } = useScrollToShowAppShell();

  const filteredFaqs = faqs?.faqs.filter(
    (faq: any) => faq.program && faq.program.length === 0,
  );

  const structuredJSON = JSON.stringify(faqData);

  return (
    <>
      <Helmet>
        <title>FAQs | Circle of Humanity - Answers to Your Questions</title>
        <meta
          name="description"
          content="Have questions about Circle of Humanity and our initiatives? Find answers to common queries about our operations, donation options, beneficiary selection, and more on our FAQ page."
        />
        <meta
          name="keywords"
          content="FAQs, Circle of Humanity, Egyptian Food Bank, donor questions, NGO help, donation information, beneficiary criteria, Zakat, Sadaqah Jariyah, food donations, admin costs"
        />
        <link rel="canonical" href="https://coh.efb.eg/faqs" />
        <meta
          property="og:title"
          content="Frequently Asked Questions | Circle of Humanity"
        />
        <meta
          property="og:description"
          content="Looking for answers? Visit our FAQ page for detailed information on how Circle of Humanity operates, how we manage donations, and how you can help. Get all the answers to your questions here."
        />
        <meta property="og:url" content="https://coh.efb.eg/faqs" />
        <meta property="og:site_name" content={t("siteName")} />
        <script className="structured-data-list" type="application/ld+json">
          {structuredJSON}
        </script>
      </Helmet>
      <div ref={headerRef}>
        <Header title={<Trans i18nKey="faqsPage.title" />} picture="faqs.jpg" />
      </div>

      <AppShell
        navbarClass={clsx(
          "fixed top-0 -z-10 w-full bg-[#CDCFC9] opacity-0 transition-opacity duration-300 ease-in-out",
          { "opacity-100 z-10": isScrolled },
        )}
      />

      <div className="mx-4 my-16 md:mx-auto md:my-24 md:px-64">
        <FAQsComponent data={filteredFaqs} isProgram={false} />
      </div>

      <div className="flex">
        <div className="-mb-4 flex-1 bg-[#E2DDD7] px-4 py-6 md:px-6 md:py-16">
          <div className="flex flex-col gap-8 md:flex-row md:gap-16">
            <div className="flex flex-col self-stretch">
              <h2 className="text-20px md:text-32px font-bold max-md:max-w-full">
                {t("faqsPage.question")}
              </h2>
              <p className="pt-4 text-lg md:w-3/4 md:pt-8 md:text-2xl">
                {t("faqsPage.contact_support")}
              </p>
            </div>

            <div className="flex w-full flex-1 flex-col justify-items-stretch gap-6 md:flex-row">
              <div className="flex-1">
                <ContactCard
                  IconComponent={MailIcon}
                  headerText={t("information.chat")}
                  contactInfo={contact?.email}
                  linkType="mailto"
                  bgColor="#EDEAE4"
                />
              </div>
              <div className="flex-1">
                <ContactCard
                  IconComponent={PhoneIcon}
                  headerText={t("information.call")}
                  contactInfo={contact?.number}
                  linkType="tel"
                  bgColor="#EDEAE4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
