import { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useTranslation } from "react-i18next";

export const SliderSection: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  const slides = [
    {
      img: "/slider-1.png",
      heading: t("aboutPage.sliderSection.slides.slide1.heading"),
      subheading: t("aboutPage.sliderSection.slides.slide1.subheading"),
      description: t("aboutPage.sliderSection.slides.slide1.description")
    },
    {
      img: "/slider-2.png",
      heading: t("aboutPage.sliderSection.slides.slide2.heading"),
      subheading: t("aboutPage.sliderSection.slides.slide2.subheading"),
      description: t("aboutPage.sliderSection.slides.slide2.description")
    },
    {
      img: "/slider-3.png",
      heading: t("aboutPage.sliderSection.slides.slide3.heading"),
      subheading: t("aboutPage.sliderSection.slides.slide3.subheading"),
      description: t("aboutPage.sliderSection.slides.slide3.description")
    }
  ];

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <section className="flex flex-col md:flex-row w-full mt-16 md:mb-32" dir={isRTL ? "rtl" : "ltr"}>
      <div className="flex-1 flex flex-col p-6 justify-center">
        <h1 className="text-xl text-center md:text-start md:text-5xl font-semibold">
          {t("aboutPage.sliderSection.title")}: {t("aboutPage.period")}
        </h1>
        <div className="justify-center md:justify-start items-center gap-5 inline-flex mt-4 md:mt-12">
          <button
            className={`w-12 h-12 rounded justify-center items-center gap-2.5 flex ${
              currentSlide === 0 ? "bg-[#E2DDD7]" : "bg-[#232329]"
            }`}
            onClick={isRTL ? handleNextSlide : handlePrevSlide}
            disabled={currentSlide === 0}
          >
            {isRTL ? (
              <ChevronRight stroke={currentSlide === 0 ? "#232329" : "#EDEAE4"} />
            ) : (
              <ChevronLeft stroke={currentSlide === 0 ? "#232329" : "#EDEAE4"} />
            )}
          </button>
          <button
            className={`w-12 h-12 rounded justify-center items-center gap-2.5 flex ${
              currentSlide === slides.length - 1
                ? "bg-[#E2DDD7]"
                : "bg-[#232329]"
            }`}
            onClick={isRTL ? handlePrevSlide : handleNextSlide}
            disabled={currentSlide === slides.length - 1}
          >
            {isRTL ? (
              <ChevronLeft stroke={currentSlide === slides.length - 1 ? "#232329" : "#EDEAE4"} />
            ) : (
              <ChevronRight stroke={currentSlide === slides.length - 1 ? "#232329" : "#EDEAE4"} />
            )}
          </button>
        </div>
      </div>

      <div className="relative md:flex-1 px-8 mb-8 md:px-0 h-[250px] md:h-[500px] md:max-w-sm">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute px-6 h-full rounded-lg transition-all duration-500 ease-in-out ${
              currentSlide === index
                ? "top-0 left-0 z-40 opacity-100 w-full"
                : ""
            } ${
              index < currentSlide 
                ? "opacity-0 -translate-x-full -translate-y-full" 
                : ""
            } ${
              index > currentSlide 
                ? `top-${(index - currentSlide) * 5} ${
                    isRTL ? "right-1/2 translate-x-1/2" : "left-1/2 -translate-x-1/2"
                  } z-${40 - (index - currentSlide) * 10} w-[${100 - (index - currentSlide) * 10}%]`
                : ""
            }`}
          >
            <img
              src={slide.img}
              alt={`Slide ${index + 1}`}
              className="w-full h-full object-cover object-center rounded-lg"
            />
          </div>
        ))}
      </div>

      <div className="flex-1 flex flex-col md:flex-col px-6 pt-6 md:pt-0 md:py-4 md:pl-16 md:pr-8 md:justify-end overflow-hidden">
        <div className="mt-4">
          <div className="h-8 md:h-20 overflow-hidden relative">
            <h1 
              key={currentSlide}
              className="text-lg md:text-5xl animate-roll-up absolute w-full"
            >
              {slides[currentSlide].heading}
            </h1>
          </div>
          
          <div className="h-8 overflow-hidden relative">
            <p 
              key={currentSlide}
              className="animate-roll-up absolute w-full"
            >
              {slides[currentSlide].subheading}
            </p>
          </div>
          
          <div className="h-32 overflow-hidden relative mt-1 md:mt-4">
            <p 
              key={currentSlide}
              className="animate-roll-up absolute w-full text-sm md:text-md"
            >
              {slides[currentSlide].description}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
