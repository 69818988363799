import DatePicker from "@/components/Inputs/DayDatePicker";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radiogroup";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { PhoneNumberField } from "./PhoneNumberField";
import CustomInputField from "./CustomInputFiled";
import { Input } from "@/components/ui/input";
import DropDown from "@/components/ui/dropdown";
import { useTranslation } from "react-i18next";
import {
  LivestockBreed,
  LivestockType,
  SpecialProgramType,
  SuggestedAmountType,
} from "@/types/programs";
import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Form, Formik, FormikErrors } from "formik";
import { cn } from "@/lib/utils";
import { useGetCitiesByCountryName } from "@/api/geography";
import FormikCustomSelectField from "@/components/ui/FormikCustomSelectField";
import { useQuery } from "@tanstack/react-query";
import { deliverySlotsQueryOptions } from "@/api/delivery";
import { DeliveryType } from "@/types/delivery";
import useCurrencyStore from "@/store/currencyStore";
import { isRTLLanguage } from "@/utils/helpers";
import * as Yup from "yup";

type SpecialProgramFormProps = {
  readonly livestockList: LivestockType[];
  readonly zakatErrors: Record<string, string>;
  readonly specialType?: SpecialProgramType;
  readonly zakatAmount?: SuggestedAmountType;
};

type InnerFormProps = {
  setFieldValue: (
    field: string,
    value: any,
  ) => Promise<void | FormikErrors<any>>;
  values: any;
  submitCount: number;
  errors: Record<string, string>;
  livestockList: LivestockType[];
  delivery?: DeliveryType;
};

const InnerForm = ({
  setFieldValue,
  values,
  submitCount,
  errors,
  livestockList,
}: Readonly<InnerFormProps>) => {
  const { t, i18n } = useTranslation();
  const { currency } = useCurrencyStore();
  const [livestockObject, setLivestockObject] = useState<LivestockType | null>(
    values.livestock
      ? livestockList?.find((l) => l.id === values.livestock)!
      : null,
  );
  const [deliveryWindow, setDeliveryWindow] = useState<DeliveryType>();
  const { data: egyptianCities, isLoading: citiesLoading } =
    useGetCitiesByCountryName("Egypt", { lang: i18n.language });
  const { data: deliverySlots } = useQuery(
    deliverySlotsQueryOptions(deliveryWindow ? deliveryWindow.id : "", {
      page_size: "null",
    }),
  );
  const {
    isDelivery,
    setIsDelivery,
    setFormError,
    setInputAmount,
    isShare,
    setIsShare,
    setDeliveryDate,
  } = useFormStore();

  const getRadioValue = () => {
    if (isDelivery === true) {
      return "true";
    } else if (isDelivery === false) {
      return "false";
    }
    return undefined;
  };

  const getAmountByCurrency = (livestock: LivestockType) => {
    if (currency === "EGP") {
      return `EGP ${livestock.amount_egp}`;
    } else {
      return `$ ${livestock.amount_usd}`;
    }
  };

  useEffect(() => {
    if (submitCount > 0) {
      const hasErrors = Object.keys(errors).length > 0;
      setFormError(hasErrors);
    }
  }, [errors, submitCount]);

  useEffect(() => {
    setInputAmount(
      values.quantity *
        ((currency === "EGP"
          ? livestockObject?.amount_egp
          : livestockObject?.amount_usd) ?? 0),
    );
  }, [values.quantity, livestockObject, currency]);

  useEffect(() => {
    setLivestockObject(
      livestockList?.find((l) => l.id === values.livestock) ?? null,
    );
    if (isShare === false) {
      setIsDelivery(false);
      setFieldValue("isDelivery", false);
    }
  }, [values.livestock]);

  useEffect(() => {
    setDeliveryWindow(livestockObject?.delivery);
    setFieldValue("deliveryDate", null);
    setDeliveryDate(null);
    setIsShare(livestockObject?.is_share_applicable ?? false);
  }, [livestockObject]);

  return (
    <Form className="flex flex-col gap-8">
      <DropDown
        label={t("donationFlow.donationValue")}
        name="livestock"
        options={livestockList?.map((l) => {
          return {
            label: `${l.type !== LivestockBreed.NOT_APPLICABLE ? l.type : ""} ${l.name} - ${getAmountByCurrency(l)}`,
            value: l.id,
          };
        })}
        value={values.livestock ?? ""}
        onChange={(value) => {
          setFieldValue("livestock", value);
        }}
        placeholder={t("donationFlow.donationValuePlaceholder")}
        error={!values.livestock && submitCount > 0 ? errors.livestock : ""}
      />
      <CustomInputField
        label={t("donationFlow.quantity")}
        name="quantity"
        value={values.quantity === 0 ? "" : values.quantity}
        placeholder={t("donationFlow.quantityPlaceholder")}
        pattern="\d*"
        format={(e) => {
          const numberValue = parseInt(e.target.value);
          if (!isNaN(numberValue)) {
            setFieldValue("quantity", numberValue);
          } else {
            setFieldValue("quantity", 0);
          }
        }}
      />
      {isShare && (
        <div className="flex flex-col gap-6">
          <label className="text-foreground block text-start text-xs font-bold md:text-sm">
            {t("donationFlow.receiveShare")}
          </label>
          <RadioGroup
            dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
            value={getRadioValue()}
            onValueChange={(value) => {
              setIsDelivery(value === "true");
              setFieldValue("isDelivery", value === "true");
            }}
          >
            <RadioGroupItem
              checked={values.isDelivery}
              value="true"
              label={t("donationFlow.yesShare")}
              error={values.isDelivery === null && submitCount > 0}
            />
            {isDelivery && (
              <div className="mb-4 flex flex-col gap-3.5 rounded-lg border border-[#D3D0CA] bg-[#E4E1D8] p-8">
                <p className="mb-2.5 text-sm">
                  {t("donationFlow.deliveryAddressPlaceholder")}
                </p>
                <CustomInputField
                  label={t("donationFlow.deliveryAddress")}
                  name="deliveryAddress"
                  placeholder={t("donationFlow.enterAddress")}
                />
                {!citiesLoading && egyptianCities && (
                  <FormikCustomSelectField
                    label={t("donationFlow.city")}
                    name="deliveryCity"
                    options={egyptianCities?.map((city) => ({
                      label: city.name,
                      value: city.name,
                    }))}
                    searchable={true}
                    searchPlaceholder={t("donationFlow.enterCity")}
                  />
                )}
                <PhoneNumberField
                  label={t("donationFlow.phonemandatory")}
                  name="deliveryPhoneNumber"
                  placeholder={t("donationFlow.enterPhone")}
                  optional={false}
                />
                <div className="mb-6 w-full">
                  <label
                    htmlFor="collection_date"
                    className="text-forground mb-1 block text-sm font-medium"
                  >
                    {t("donationFlow.deliveryDay")}
                  </label>
                  <DatePicker
                    selected={values.deliveryDate}
                    name="deliveryDate"
                    onChange={setFieldValue}
                    placeholderText={t("donationFlow.deliveryDayPlaceholder")}
                    delivery={deliveryWindow}
                    deliverySlots={deliverySlots}
                    error={
                      !values.deliveryDate && submitCount > 0
                        ? errors.deliveryDate
                        : ""
                    }
                  />
                </div>
              </div>
            )}
            <RadioGroupItem
              checked={!values.isDelivery}
              value="false"
              label={t("donationFlow.noShare")}
              error={values.isDelivery === null && submitCount > 0}
            />
          </RadioGroup>
          {!values.isDelivery && submitCount > 0 && (
            <p className="-mt-6 text-sm text-[#F04438]">{errors.isDelivery}</p>
          )}
        </div>
      )}
    </Form>
  );
};

const SpecialProgramForm = forwardRef<any, SpecialProgramFormProps>(
  ({ livestockList, zakatErrors, specialType, zakatAmount }, ref) => {
    const { t } = useTranslation();
    const { currency } = useCurrencyStore();

    const {
      livestock,
      quantity,
      setQuantity,
      setLivestock,
      deliveryDate,
      deliveryPhoneNumber,
      setDeliveryPhoneNumber,
      deliveryAddress,
      setDeliveryAddress,
      deliveryCity,
      setDeliveryCity,
      setInputAmount,
      isDelivery,
      setDeliveryCountryDial,
      deliveryCountryDial,
      nextStep,
    } = useFormStore();

    const handleQuantityChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setQuantity(0);
        const value = event.target.value;

        const numberValue = parseInt(value);
        if (!isNaN(numberValue)) {
          setQuantity(numberValue);
        } else {
          setQuantity(0);
        }
      },
      [setQuantity],
    );

    const validationSchema = Yup.object().shape({
      isDelivery: Yup.boolean().required(t("yup.isDeliveryRequired")),
      deliveryPhoneNumber: isDelivery
        ? Yup.string()
            .min(9, t("yup.phoneNumberInvalid"))
            .max(15, t("yup.phoneNumberInvalid"))
            .required(t("yup.phoneNumberRequired"))
        : Yup.string().nullable(),
      deliveryAddress: isDelivery
        ? Yup.string()
            .min(1, t("yup.addressRequired"))
            .required(t("yup.addressRequired"))
        : Yup.string().nullable(),
      deliveryCity: isDelivery
        ? Yup.string()
            .min(1, t("yup.cityRequired"))
            .required(t("yup.cityRequired"))
        : Yup.string().nullable(),
      deliveryDate: isDelivery
        ? Yup.date().required(t("yup.deliveryDateRequired"))
        : Yup.date().nullable(),
      quantity: Yup.number().min(1, t("yup.quantityRequired")),
      livestock: Yup.string()
        .required(t("yup.donationValueRequired"))
        .min(1, t("yup.donationValueRequired")),
      inputAmount: Yup.number().min(1, t("yup.amountInvalid")),
    });

    const formInitialValue = {
      deliveryPhoneNumber: deliveryPhoneNumber,
      country_dial: deliveryCountryDial,
      deliveryAddress: deliveryAddress,
      deliveryCity: deliveryCity,
      deliveryDate: deliveryDate,
      quantity: quantity,
      livestock: livestock,
      isDelivery: isDelivery,
    };

    useEffect(() => {
      if (specialType === SpecialProgramType.ZAKAT_FITR && zakatAmount) {
        setInputAmount(
          quantity *
            ((currency === "EGP"
              ? zakatAmount.amount_egp
              : zakatAmount.amount_usd) ?? 0),
        );
      }
    }, [quantity, currency]);

    if (specialType === SpecialProgramType.ZAKAT_FITR) {
      return (
        <div className="flex flex-col gap-2">
          <label
            htmlFor="familyMembers"
            className="text-foreground block text-start text-xs font-bold md:text-sm"
          >
            {t("donationFlow.familyMembers")}
          </label>
          <Input
            name="familyMembers"
            id="familyMembers"
            placeholder={t("donationFlow.familyMembersPlaceHolder")}
            value={quantity === 0 ? "" : quantity}
            onChange={handleQuantityChange}
            className={cn({
              "border-[#F04438]": zakatErrors.quantity,
            })}
            pattern="\d*"
          />
          {zakatErrors.quantity && (
            <p className="mt-1 text-sm text-[#F04438]">
              {zakatErrors.quantity}
            </p>
          )}
        </div>
      );
    }

    return (
      <Formik
        initialValues={formInitialValue}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
          setQuantity(values.quantity);
          setLivestock(values.livestock ?? "");
          setDeliveryAddress(values.deliveryAddress);
          setDeliveryCity(values.deliveryCity);
          setDeliveryPhoneNumber(values.deliveryPhoneNumber);
          setDeliveryCountryDial(values.country_dial);
          nextStep();
        }}
        innerRef={ref}
      >
        {({ values, setFieldValue, submitCount, errors }) => (
          <InnerForm
            setFieldValue={setFieldValue}
            values={values}
            submitCount={submitCount}
            errors={errors}
            livestockList={livestockList}
          />
        )}
      </Formik>
    );
  },
);

export default SpecialProgramForm;
