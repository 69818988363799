import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button } from "../ui/button";
import { isRTLLanguage } from "@/utils/helpers";
import { cn } from "@/lib/utils";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Form, Formik, FormikErrors } from "formik";
import AmountInput from "@/components/Inputs/AmountInput";
import * as Yup from "yup";
import { useFormStore } from "@/contexts/paymentFlowContext";
import DropDown from "../ui/dropdown";
import { useGetDonationTypes } from "@/api/donation_type";
import { useInfinitePrograms } from "@/api/program";
import { useRouter } from "@tanstack/react-router";
import clsx from "clsx";

type QuickDonationFormProps = {
  readonly setIsOpen: (isOpen: boolean) => void;
};
const QuickDonationForm = ({ setIsOpen }: QuickDonationFormProps) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { navigate } = useRouter();
  const [isNextStep, setIsNextStep] = useState(false);
  const {
    setInputAmount,
    donationType,
    setDonationType,
    setSelectedProgram,
    inputAmount,
    selectedProgram,
    nextStep,
    reset,
  } = useFormStore();
  const { data: donationTypes } = useGetDonationTypes({
    lang: i18n.language,
    type: "Constant",
  });
  const {
    data: programs,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinitePrograms({
    page_size: "8",
    lang: i18n.language,
    donationtypeId: donationType ?? "",
    isSpecial: "False",
    isItemization: "False",
  });

  const validationSchema = Yup.object().shape({
    inputAmount: Yup.number().min(1).required(t("yup.amountRequired")),
    selectedProgram: Yup.string().required(t("yup.causeRequired")),
    donationType: Yup.string().required(t("yup.donationTypeRequired")),
  });

  const handleInputChange = useCallback(
    ({
      event,
      setFieldValue,
    }: {
      event: ChangeEvent<HTMLInputElement>;
      setFieldValue: (
        field: string,
        value: any,
      ) => Promise<void | FormikErrors<any>>;
    }) => {
      setFieldValue("inputAmount", 0);
      const value = event.target.value;
      if (value === "") {
        setFieldValue("inputAmount", null);
      } else {
        const numberValue = parseInt(value);
        if (!isNaN(numberValue)) {
          setFieldValue("inputAmount", numberValue);
        } else {
          setFieldValue("inputAmount", null);
        }
      }
    },
    [setInputAmount],
  );

  const flatPrograms = useMemo(
    () => programs?.pages.map((page) => page.results).flat(),
    [programs],
  );

  const initialValues = {
    selectedProgram: selectedProgram?.id,
    donationType: donationType,
    inputAmount: inputAmount,
  };

  useEffect(() => {
    if (isNextStep && selectedProgram) {
      navigate({
        to: `/paymentFlow/${selectedProgram.id}`,
        search: { backUrl: location.pathname },
      });
    }
  }, [isNextStep, selectedProgram]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setSelectedProgram(
          flatPrograms?.find(
            (program) => program.id === values.selectedProgram,
          ) ?? null,
        );
        setInputAmount(values.inputAmount);
        nextStep();
        setIsNextStep(true);
        setIsOpen(false);
      }}
    >
      {({ values, errors, setFieldValue, submitCount }) => (
        <Form className="flex flex-col gap-2">
          <p className="text-base font-bold">{t("quickDonation.title")}</p>
          <DropDown
            name="donationType"
            options={
              donationTypes
                ? donationTypes.map((type) => ({
                    label: type.title,
                    value: type.id,
                  }))
                : []
            }
            placeholder={t("donationFlow.donationtype")}
            onChange={(value) => {
              setDonationType(value as string);
              setFieldValue("donationType", value);
              setFieldValue("selectedProgram", "");
            }}
            error={submitCount > 0 ? errors.donationType : ""}
          />
          <DropDown
            name="selectedProgram"
            options={
              flatPrograms
                ? flatPrograms.map((program) => ({
                    label: program.title,
                    value: program.id,
                  }))
                : []
            }
            placeholder={t("donateNow.donationCause")}
            value={values.selectedProgram}
            onChange={(value) => {
              setFieldValue("selectedProgram", value);
            }}
            onScroll={() => {
              if (hasNextPage && !isFetchingNextPage) {
                fetchNextPage();
              }
            }}
            error={submitCount > 0 ? errors.selectedProgram : ""}
            maxHeigh="!max-h-40"
          />
          <div className="mt-2 w-full">
            <AmountInput
              value={
                values.inputAmount !== null ? values.inputAmount.toString() : ""
              }
              onChange={(e) => handleInputChange({ event: e, setFieldValue })}
              className={cn({
                "border-[#F04438]": errors.inputAmount && submitCount > 0,
              })}
              placeholder={t("donateNow.enterDonationAmount")}
            />
            {errors.inputAmount && submitCount > 0 && (
              <p className="mt-1 text-sm text-[#F04438]">
                {t("yup.amountInvalid")}
              </p>
            )}
          </div>
          <Button type="submit" className="mt-2 h-[54px] px-10 text-base">
            {t("quickDonation.proceedPayment")}
          </Button>
          <Button
            type="button"
            className="text-base"
            variant="underline"
            onClick={() => {
              reset();
              setIsOpen(false);
            }}
          >
            {t("quickDonation.cancel")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export const QuickDonation = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { i18n } = useTranslation();

  const btnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <DropdownMenu
      dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
      onOpenChange={setIsOpen}
      open={isOpen}
      modal={false}
    >
      <DropdownMenuTrigger asChild>
        <Button
          ref={btnRef}
          className={cn(
            "hover:bg-primary flex h-[44px] w-full md:w-[180px] justify-between rounded-lg pl-4 pr-3 py-4 text-sm focus:outline-none md:focus:ring-0",
            isOpen ? "invisible" : "",
          )}
        >
          <p className="w-full text-start font-bold">
            {t("quickDonation.title")}
          </p>
          {isOpen ? (
            <ChevronUp className={cn("ml-auto h-4 w-4")} />
          ) : (
            <ChevronDown className={cn("ml-auto h-4 w-4")} />
          )}
        </Button>
      </DropdownMenuTrigger>
      {btnRef.current && !isMobile ? (
        <DropdownMenuContent
          style={{
            marginTop: `-${btnRef.current.clientHeight}px`,
          }}
          className="bg-background w-[426px] border-none p-6 text-[14px]"
          side="bottom"
          align="end"
          avoidCollisions={false}
          hideWhenDetached={true}
        >
          <QuickDonationForm setIsOpen={setIsOpen} />
        </DropdownMenuContent>
      ) : (
        <div
          className={clsx(
            "fixed inset-0 z-30 flex items-end justify-center bg-black bg-opacity-50",
            {
              hidden: !isOpen,
            },
          )}
        >
          <div className="bg-background w-full overflow-hidden rounded-t-2xl p-6">
            <QuickDonationForm setIsOpen={setIsOpen} />
          </div>
        </div>
      )}
    </DropdownMenu>
  );
};
