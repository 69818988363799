import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { CarouselComponent } from "@/pages/home/sections/CarouselComponent";
import { useTranslation } from "react-i18next";

export const AwardsCarousel: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isRTL, setIsRTL] = useState(false);

  useEffect(() => {
    setIsRTL(i18n.dir() === "rtl");
  }, [i18n.language]);

  const [scrollPrev, setScrollPrev] = useState<() => void>(() => () => {});
  const [scrollNext, setScrollNext] = useState<() => void>(() => () => {});
  const [canScrollPrevFunc, setCanScrollPrevFunc] = useState<() => boolean>(
    () => () => false
  );
  const [canScrollNextFunc, setCanScrollNextFunc] = useState<() => boolean>(
    () => () => false
  );
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(true);

  const handleSetScrollFunctions = (functions: any) => {
    setScrollPrev(() => functions.scrollPrev);
    setScrollNext(() => functions.scrollNext);
    setCanScrollNextFunc(() => functions.canScrollNext);
    setCanScrollPrevFunc(() => functions.canScrollPrev);
  };

  const handleScrollPrev = () => {
    (isRTL ? scrollNext : scrollPrev)();
    setCanScrollPrev(canScrollPrevFunc());
    setCanScrollNext(canScrollNextFunc());
  };

  const handleScrollNext = () => {
    (isRTL ? scrollPrev : scrollNext)();
    setCanScrollPrev(canScrollPrevFunc());
    setCanScrollNext(canScrollNextFunc());
  };

  const carouselItems = [
    {
      title: t("aboutPage.awardsCarousel.carouselItems.item1.title"),
      imageSrc: "/awards-1.png",
    },
    {
      title: t("aboutPage.awardsCarousel.carouselItems.item2.title"),
      imageSrc: "/awards-2.png",
    },
    {
      title: t("aboutPage.awardsCarousel.carouselItems.item3.title"),
      imageSrc: "/awards-3.png",
    },
  ];

  return (
    <div className="md:flex">
      <div className="flex flex-col justify-center md:w-1/3">
        <h1 className="md:text-6xl text-2xl font-semibold">
          {t("aboutPage.awardsCarousel.title")}
        </h1>
        <h6 className="md:text-xl text-lg mt-4 pr-4">
          {t("aboutPage.awardsCarousel.description")}
        </h6>
        <div className="gap-4 mt-8 hidden md:flex">
          <button
            className={`w-12 h-12 rounded justify-center items-center gap-2.5 flex ${
              canScrollPrev ? "bg-[#232329]" : "bg-[#E2DDD7]"
            }`}
            onClick={handleScrollPrev}
            disabled={!canScrollPrev}
          >
            {isRTL ? (
              <ChevronRight stroke={canScrollPrev ? "#EDEAE4" : "#232329"} />
            ) : (
              <ChevronLeft stroke={canScrollPrev ? "#EDEAE4" : "#232329"} />
            )}
          </button>
          <button
            className={`w-12 h-12 rounded justify-center items-center gap-2.5 flex ${
              canScrollNext ? "bg-[#232329]" : "bg-[#E2DDD7]"
            }`}
            onClick={handleScrollNext}
            disabled={!canScrollNext}
          >
            {isRTL ? (
              <ChevronLeft stroke={canScrollNext ? "#EDEAE4" : "#232329"} />
            ) : (
              <ChevronRight stroke={canScrollNext ? "#EDEAE4" : "#232329"} />
            )}
          </button>
        </div>
      </div>
      <div className="md:w-2/3 mt-4 md:mt-0 rtl:flex-row-reverse">
        <CarouselComponent
          imagesMd={2}
          imagesLg={3}
          setScrollFunctions={handleSetScrollFunctions}
          hideArrows
        >
          {carouselItems.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col justify-between rounded-[16px] h-[298px] aspect-9/16 min-w-[250px] max-w-[250px] items-stretch md:min-w-[350px] md:max-w-[350px] ${
                index % 2 === 0 ? "bg-[#E2DDD7]" : "border border-[#E2DDD7]"
              }`}
            >
              <h6 className="font-semibold p-6">{item.title}</h6>
              <img
                className="object-cover object-center rounded-[16px] h-[186px]"
                src={item.imageSrc}
                alt={item.title}
              />
            </div>
          ))}
        </CarouselComponent>
      </div>
    </div>
  );
};
