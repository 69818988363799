import { cn } from "@/lib/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useField } from "formik";
import countryCodes from "@/utils/phonenumbers.json";
import timezones from "@/utils/timezone.json";
import { useEffect, useState } from "react";
import { isRTLLanguage } from "@/utils/helpers";
import { useTranslation } from "react-i18next";

type PhonenumberInputProps = {
  name: string;
  label: string;
  placeholder: string;
  optional: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  setCountryDial?: (dial: string | null) => void;
};

export const PhoneNumberField = (props: PhonenumberInputProps) => {
  const [field, meta] = useField(props);
  const [dialTouched, setDialTouched] = useState(false);
  const { i18n } = useTranslation();
  const errorClass =
    (props.error && props.error !== "") || (meta.touched && meta.error)
      ? "border-[#F04438]"
      : "";
  const [country_dial, country_meta, helpers] = useField({
    name: "country_dial",
    type: "select",
  });

  useEffect(() => {
    if (dialTouched) {
      helpers.setValue(country_meta.value);
      props.setCountryDial?.(country_meta.value);
    } else if (!country_meta.initialValue) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const countryCode = Object.entries(timezones)
        .find(([_, tz]) => (tz as string[]).includes(userTimezone))
        ?.at(0);
      const dial = countryCodes.find((c) => c.code === countryCode)?.dial_code;
      if (dial) {
        helpers.setValue(dial);
        props.setCountryDial?.(dial);
      }
    }
  }, [helpers, country_meta.value]);

  return (
    <div className="flex flex-col gap-2">
      <label
        dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
        htmlFor="phoneNumber"
        className="text-foreground block text-start text-xs font-bold md:text-sm"
      >
        {props.label}
      </label>
      <div className="relative flex w-full flex-col gap-2">
        <div
          className={cn(
            "h-[58px] p-2 bg-background border-[#CCC2BF] flex gap-0 rounded-md border w-full",
            errorClass,
          )}
        >
          <div className="flex items-center">
            <Select
              {...country_dial}
              onValueChange={(val) => {
                helpers.setValue(val.split("-")[0]);
                setDialTouched(true);
              }}
            >
              <SelectTrigger className="text-foreground rounded-md border-transparent bg-[#E2DDD7] font-bold sm:text-sm">
                <SelectValue placeholder="Country">
                  {country_dial.value}
                </SelectValue>
              </SelectTrigger>
              <SelectContent
                side="bottom"
                avoidCollisions={false}
                sticky="always"
                className="left-0 top-0 translate-y-[-12%] transform"
              >
                {countryCodes.map((country, i) => (
                  <SelectItem
                    key={i}
                    value={country.dial_code + "-" + i.toString()}
                  >
                    {country.dial_code} {country.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <input
            type="text"
            {...field}
            {...props}
            className={cn(
              "bg-background block w-full rounded-md border-opacity-0 p-2 focus:border-transparent focus:outline-none focus:ring-0 md:text-base text-sm rtl:text-right",
              meta.touched && !meta.error ? "font-bold" : "",
              field.value === "" ? "placeholder-[#4F4754]" : "",
            )}
            pattern="\d*"
            dir={"ltr"}
            placeholder={props.placeholder}
          />
        </div>
        {(props.error && props.error !== "") || (meta.touched && meta.error) ? (
          <div className="mt-2 text-start text-sm text-[#F04438]">
            {props.error ?? meta.error}
          </div>
        ) : null}
      </div>
    </div>
  );
};
