import { AppShell } from "@/components/Navbar";
import { useTranslation } from "react-i18next";
import ProgramListing from "./sections/listAll";
import { Helmet } from "react-helmet-async";
import { Link } from "@tanstack/react-router";
import { isRTLLanguage } from "@/utils/helpers";

export enum ProgramSortOptions {
  RANK = "rank",
  LAUNCHED = "-created_at",
  RAISED = "-raised_percentage",
}

export const ProgramCategories = [
  "",
  "kids",
  "health",
  "orphans",
  "nursery children",
  "single mothers",
  "refugees",
  "disabled individuals",
  "assisted living",
];
export const donations = ["", "zakat", "sadaqah", "jariya", "aqeeqah"];

export const ProgramsPage = () => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Support Our Causes | Circle of Humanity</title>
        <meta
          name="description"
          content="Explore and support the various causes at Circle of Humanity. Each contribution helps us fight hunger and provide relief in crisis-affected regions. Learn more about our projects and how you can help."
        />
        <meta
          name="keywords"
          content="support causes, humanitarian projects, hunger relief, crisis aid, Egypt NGO, donate"
        />
        <link rel="canonical" href="https://coh.efb.eg/programs" />
        <meta
          property="og:title"
          content="Explore Our Causes | Circle of Humanity"
        />
        <meta
          property="og:description"
          content="Your support is crucial. Explore the humanitarian causes championed by Circle of Humanity to alleviate hunger and crisis impacts. Get involved today."
        />
        <meta property="og:url" content="https://coh.efb.eg/programs" />
        <meta property="og:site_name" content={t("siteName")} />
      </Helmet>
      <div className="bg-foreground flex justify-between items-center px-4 h-14 md:px-[24px]">
        <div className="flex flex-row gap-1 text-white">
          {t("join.first_part")}{" "}
          <Link
            aria-label={t("join.first_part") + " " + t("join.second_part")}
            to="/community-challenge"
            search={{ tab: "" }}
          >
            <p className="font-bold text-white underline"> {t("join.second_part")}</p>
          </Link>
        </div>
        <Link
            aria-label={t("join.first_part") + " " + t("join.second_part")}
            to="/community-challenge"
            search={{ tab: "" }}
          >
          {(isRTLLanguage(i18n.language) ? <img src="/coh-logo-ar-white.svg" className="h-8" /> : <img src="/coh-logo-white.svg" className="h-8" />)}
        </Link>
      </div>
      <AppShell
        theme="white"
        navbarClass="top-14 absolute w-full sticky bg-[#CDCFC9] inset-0"
      />
      <div className="m-4 md:m-6 md:mb-0">
        <h2 className="text-24px md:text-48px font-bold">
          {t("ourPrograms.title")}
        </h2>

        <div className="pt-6">
          <ProgramListing />
        </div>
      </div>
    </>
  );
};
