import React from "react";
import { CarouselComponent } from "@/pages/home/sections/CarouselComponent";
import { useTranslation } from "react-i18next";


interface Expenditure {
  amount: string;
  title: string;
  description: string;
}

interface ExpenditureCardProps {
  amount: string;
  title: string;
  description: string;
  bgColor: string;
}

const ExpenditureCard: React.FC<ExpenditureCardProps> = ({ amount, title, description, bgColor }) => (
  <div className={`flex flex-col justify-between md:justify-start p-6 md:p-4 rounded-md ${bgColor} w-[292px] min-h-[220px] md:w-auto md:h-auto`}>
    <div>
        <h3 className="text-lg md:text-xl md:text-3xl">{amount}</h3>
        <p className="font-bold">{title}</p>
    </div>
    <p className="mt-2">{description}</p>
  </div>
);

export const Expenditures: React.FC = () => {
  const { t } = useTranslation();

  const expenditureData: Expenditure[] = [
    {
      amount: t("aboutPage.expenditureRecords.item1.amount"),
      title: t("aboutPage.expenditureRecords.item1.title"),
      description: t("aboutPage.expenditureRecords.item1.description"),
    },
    {
      amount: t("aboutPage.expenditureRecords.item2.amount"),
      title: t("aboutPage.expenditureRecords.item2.title"),
      description: t("aboutPage.expenditureRecords.item2.description"),
    },
    {
      amount: t("aboutPage.expenditureRecords.item3.amount"),
      title: t("aboutPage.expenditureRecords.item3.title"),
      description: t("aboutPage.expenditureRecords.item3.description"),
    },
    {
      amount: t("aboutPage.expenditureRecords.item4.amount"),
      title: t("aboutPage.expenditureRecords.item4.title"),
      description: t("aboutPage.expenditureRecords.item4.description"),
    },
    {
      amount: t("aboutPage.expenditureRecords.item5.amount"),
      title: t("aboutPage.expenditureRecords.item5.title"),
      description: t("aboutPage.expenditureRecords.item5.description"),
    },
    {
      amount: t("aboutPage.expenditureRecords.item6.amount"),
      title: t("aboutPage.expenditureRecords.item6.title"),
      description: t("aboutPage.expenditureRecords.item6.description"),
    },
    {
      amount: t("aboutPage.expenditureRecords.item7.amount"),
      title: t("aboutPage.expenditureRecords.item7.title"),
      description: t("aboutPage.expenditureRecords.item7.description"),
    },
    {
      amount: t("aboutPage.expenditureRecords.item8.amount"),
      title: t("aboutPage.expenditureRecords.item8.title"),
      description: t("aboutPage.expenditureRecords.item8.description"),
    }
  ];

  // Function to determine the background color based on row and column index
  const getBackgroundColor = (rowIndex: number, colIndex: number): string => {
    const isEvenRow = rowIndex % 2 === 0;
    const isEvenCol = colIndex % 2 === 0;

    return (isEvenRow && isEvenCol) || (!isEvenRow && !isEvenCol)
      ? "border border-[#E2DDD7]"
      : "bg-[#E2DDD7]";
  };

  // Split data into rows of 4 items each for the grid layout
  const rows = Array.from({ length: Math.ceil(expenditureData.length / 4) }, (_, rowIndex) => {
    const rowData = expenditureData.slice(rowIndex * 4, rowIndex * 4 + 4);

    return (
      <div className="grid grid-cols-4 gap-4 my-4" key={rowIndex}>
        {rowData.map((item, colIndex) => (
          <ExpenditureCard
            key={`${rowIndex}-${colIndex}`}
            {...item}
            bgColor={getBackgroundColor(rowIndex, colIndex)}
          />
        ))}
      </div>
    );
  });

  return (
    <>
      {/* Grid layout for medium screens and above */}
      <div className="hidden md:block">
        {rows}
      </div>

      {/* Carousel for small screens */}
      <div className="mt-4 md:hidden">
        <CarouselComponent imagesMd={2} imagesLg={3} hideArrows>
            {expenditureData.map((item, index) => {
            const rowIndex = Math.floor(index / 4); // Calculate row index for background color
            const colIndex = index % 4; // Calculate column index for background color

            return (
                <ExpenditureCard
                key={index}
                {...item}
                bgColor={getBackgroundColor(rowIndex, colIndex)}
                />
            );
            })}
        </CarouselComponent>
      </div>
    </>
  );
};