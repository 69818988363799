import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

export const MealsSection: React.FC = () => {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const cardsRef = useRef<HTMLDivElement>(null);

  // Constant side padding (in pixels) for fullscreen mode.
  const sidePadding = 24;

  // videoStyles holds inline styles for width, height, margin, etc.
  const [videoStyles, setVideoStyles] = useState<React.CSSProperties>({});
  // Save the initial (non‑fullscreen) dimensions.
  const [initialDimensions, setInitialDimensions] = useState<{ width: string; height: string }>({
    width: "0px",
    height: "0px",
  });

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // On mount, measure the video’s initial dimensions.
  useEffect(() => {
    if (videoRef.current && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const rect = videoRef.current.getBoundingClientRect();
      let width = rect.width;
      let height = rect.height;
      if (isMobile) {
        width = containerRect.width / 2;
        height = 320;
      }
      setInitialDimensions({ width: `${width}px`, height: `${height}px` });
      setVideoStyles({
        width: `${width}px`,
        height: `${height}px`,
        margin: "0 auto",
        transformOrigin: "center",
      });
    }
  }, []);

  // Update isMobile flag on window resize.
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll logic
  useEffect(() => {
    const handleScroll = () => {
      if (videoRef.current && containerRef.current && cardsRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const videoRect = videoRef.current.getBoundingClientRect();
        const cardsRect = cardsRef.current.getBoundingClientRect();

        if (isMobile) {
          // ----- MOBILE LOGIC -----
          // Calculate mobile-specific values:
          const mobileIsInViewport =
            videoRect.bottom <= window.innerHeight && containerRect.bottom > window.innerHeight;
          const mobileOutOfFocusBottom = containerRect.bottom < videoRect.height + 128;
          const mobileOutOfFocusTop = containerRect.top >= -1 * (containerRect.height / 2);

          if (!mobileIsInViewport) {
            videoRef.current?.pause();
          }

          // For mobile, we do not transition margin-top.
          // When the container’s top goes offscreen (<= 0) and the video is in viewport, enlarge from center.
          if (!isFullscreen && (mobileIsInViewport || isSticky) && containerRect.top <= 0) {
            setIsSticky(true);
            const y = cardsRect.bottom - 320;
            const marginTop = Math.min(
              window.innerHeight,
              Math.max(y * -1, 0)
            );
            setVideoStyles((prev) => ({
              ...prev,
              marginTop: `${marginTop}px`,
            }));
            if (marginTop === window.innerHeight) {
              setIsFixed(true);
              setIsFullscreen(true);
              setVideoStyles({
                width: `${window.innerWidth - sidePadding * 2}px`,
                height: `${window.innerHeight * 0.8}px`,
                margin: 0,
                transformOrigin: "center",
              });
            }
            videoRef.current?.play();
          }

          if (mobileOutOfFocusBottom && isFullscreen) {
            // When scrolling further down so that the container is out of view,
            // reset the sticky state.
            setIsFixed(false);
            setIsSticky(false);
          }

          if (!mobileOutOfFocusBottom && isFullscreen) {
            setIsFixed(true);
            setIsFullscreen(true);
            videoRef.current?.play();
          }

          if (mobileOutOfFocusTop && isFullscreen) {
            // When scrolling upward past the video, revert to initial dimensions.
            setIsFixed(false);
            setIsFullscreen(false);
            setVideoStyles({
              width: initialDimensions.width,
              height: initialDimensions.height,
              margin: "0 auto",
              transformOrigin: "center",
            });
          }
        } else {
          // ----- DESKTOP LOGIC -----
          // Compute viewport conditions.
          const isInViewport =
            containerRect.top <= window.innerHeight / 2 &&
            containerRect.bottom >= window.innerHeight / 2;
          const outOfFocusBottom = containerRect.bottom <= window.innerHeight;
          const outOfFocusTop = containerRect.top >= -window.innerHeight;

          if (!isInViewport) {
            videoRef.current?.pause();
          }

          if ((isInViewport || isSticky) && !isFullscreen) {
            setIsSticky(true);
            // Compute a margin based on containerRect.y.
            const y = containerRect.y;
            const marginTop = Math.min(window.innerHeight, Math.max(-y, 0));
            // Update only marginTop on desktop.
            setVideoStyles((prev) => ({
              ...prev,
              marginTop: `${marginTop}px`,
              marginLeft: "auto",
              marginRight: "auto",
            }));
            if (!isSticky) {
              videoRef.current?.play();
            }
            // When the video has scrolled upward fully (marginTop equals viewport height), go fullscreen.
            if (marginTop === window.innerHeight) {
              setIsFixed(true);
              setIsFullscreen(true);
              setVideoStyles({
                width: `${window.innerWidth - sidePadding * 2}px`,
                height: `${window.innerHeight * 0.8}px`,
                margin: 0,
                transformOrigin: "center",
              });
            }
          }

          if (outOfFocusBottom && isFullscreen) {
            // When scrolling further down so that the container is out of view,
            // reset the sticky state.
            setIsFixed(false);
            setIsSticky(false);
          }

          if (!outOfFocusBottom && isFullscreen) {
            setIsFixed(true);
            setIsFullscreen(true);
            videoRef.current?.play();
          }

          if (outOfFocusTop && isFullscreen) {
            // When scrolling upward past the video, revert to initial dimensions.
            setIsFixed(false);
            setIsFullscreen(false);
            setIsSticky(false);
            setVideoStyles({
              width: initialDimensions.width,
              height: initialDimensions.height,
              margin: "0 auto",
              transformOrigin: "center",
            });
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFullscreen, isSticky, isMobile, initialDimensions, sidePadding]);

  return (
    <section
      className={`p-6 mt-16 min-h-[3000px] md:min-h-[300vh] ${
        isFullscreen && !isFixed ? "flex flex-col justify-end md:justify-between" : ""
      }`}
      ref={containerRef}
    >
      <h1 className="text-2xl md:text-5xl font-semibold">{t("aboutPage.mealsSection.title")}</h1>
      <div
        className={`flex flex-wrap order-1 md:order-none flex-col md:flex-row content-start md:content-auto h-[1235px] md:h-auto md:grid md:grid-cols-5 mt-8 ${
          isFullscreen && isMobile ? "hidden" : ""
        }`}
        ref={cardsRef}
      >
        {/* First Column */}
        <div className={`w-1/2 px-2 md:px-0 md:w-auto ${isFullscreen ? "hidden" : ""}`}>
          <div className="w-full p-4 rounded-lg h-60 bg-[#E2DDD7] flex flex-col justify-end">
            <h1 className="text-5xl">{t("aboutPage.mealsSection.cards.card1.amount")}</h1>
            <p>{t("aboutPage.mealsSection.cards.card1.label")}</p>
          </div>
          <div className="relative w-full p-4 rounded-lg h-80 mt-4 bg-[url('/meals-1.jpeg')] bg-cover bg-center flex flex-col justify-end">
            <div className="absolute inset-0 bg-black/50 rounded-md"></div>
            <div className="relative">
              <h1 className="text-5xl text-[#E2DDD7]">{t("aboutPage.mealsSection.cards.card2.amount")}</h1>
              <p className="text-[#E2DDD7]">{t("aboutPage.mealsSection.cards.card2.label")}</p>
            </div>
          </div>
        </div>
        {/* Second Column */}
        <div className={`mt-12 order-3 md:order-none w-1/2 px-2 md:w-auto ${isFullscreen ? "hidden" : ""}`}>
          <div className="relative w-full p-4 rounded-lg h-80 bg-[url('/meals-2.png')] bg-cover bg-center flex flex-col justify-end">
            <div className="absolute inset-0 bg-black/50 rounded-md"></div>
            <div className="relative">
              <h1 className="text-5xl text-[#E2DDD7]">{t("aboutPage.mealsSection.cards.card3.amount")}</h1>
              <p className="text-[#E2DDD7]">{t("aboutPage.mealsSection.cards.card3.label")}</p>
            </div>
          </div>
          <div className="w-full p-4 mt-4 rounded-lg h-60 bg-[#E2DDD7] flex flex-col justify-end">
            <h1 className="text-5xl">{t("aboutPage.mealsSection.cards.card4.amount")}</h1>
            <p>{t("aboutPage.mealsSection.cards.card4.label")}</p>
          </div>
        </div>
        {/* Video Column (Desktop) */}
        {!isMobile && (
          <div
            className={`md:mt-32 order-5 md:order-none ${
              isFullscreen ? "col-span-5" : ""
            } ${isFixed ? "fixed top-0 left-1/2 transform -translate-x-1/2" : "mx-auto"}`}
          >
            <div
              className="relative rounded-lg bg-cover min-h-80 bg-center flex flex-col justify-end transition-[width,height] duration-1000 ease-in-out"
              style={videoStyles}
            >
              <video
                src="/meals.mp4"
                controls
                loop
                muted
                className="object-cover rounded-lg w-full h-full"
                ref={videoRef}
              />
            </div>
          </div>
        )}
        {/* Third Column */}
        <div className={`md:mt-12 mt-4 w-1/2 order-2 md:order-none px-2 md:w-auto ${isFullscreen ? "hidden" : ""}`}>
          <div className="w-full p-4 rounded-lg h-60 bg-[#E2DDD7] flex flex-col justify-end">
            <h1 className="text-5xl">{t("aboutPage.mealsSection.cards.card5.amount")}</h1>
            <p>{t("aboutPage.mealsSection.cards.card5.label")}</p>
          </div>
          <div className="relative w-full p-4 rounded-lg h-80 mt-4 bg-[url('/meals-3.jpeg')] bg-cover bg-center flex flex-col justify-end">
            <div className="absolute inset-0 bg-black/50 rounded-md"></div>
            <div className="relative">
              <h1 className="text-5xl text-[#E2DDD7]">{t("aboutPage.mealsSection.cards.card6.amount")}</h1>
              <p className="text-[#E2DDD7]">{t("aboutPage.mealsSection.cards.card6.label")}</p>
            </div>
          </div>
        </div>
        {/* Fourth Column */}
        <div className={`w-1/2 px-2 md:px-0 mt-4 order-4 md:order-none md:mt-0 md:w-auto ${isFullscreen ? "hidden" : ""}`}>
          <div className="relative w-full p-4 rounded-lg h-80 bg-[url('/meals-4.jpeg')] bg-cover bg-center flex flex-col justify-end">
            <div className="absolute inset-0 bg-black/50 rounded-md"></div>
            <div className="relative">
              <h1 className="text-5xl text-[#E2DDD7]">{t("aboutPage.mealsSection.cards.card7.amount")}</h1>
              <p className="text-[#E2DDD7]">{t("aboutPage.mealsSection.cards.card7.label")}</p>
            </div>
          </div>
          <div className="w-full p-4 mt-4 rounded-lg h-60 bg-[#E2DDD7] flex flex-col justify-end">
            <h1 className="text-5xl">{t("aboutPage.mealsSection.cards.card8.amount")}</h1>
            <p>{t("aboutPage.mealsSection.cards.card8.label")}</p>
          </div>
        </div>
      </div>
      {/* Mobile Video */}
      {isMobile && (
        <div
          className={`${isFixed ? "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" : "mx-auto"}`}
        >
          <div
            className={`relative rounded-lg bg-cover bg-center flex flex-col justify-end transition-[width,height] duration-1000 ease-in-out ${
              isFullscreen ? "h-[80vh]" : ""
            } ${isFixed ? "pt-12" : ""}`}
            style={videoStyles}
          >
            <video
              src="/meals.mp4"
              controls
              loop
              muted
              className="object-cover rounded-lg w-full h-full z-40"
              ref={videoRef}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default MealsSection;
